import { useState, useEffect, useCallback, useMemo } from 'react';
import { TemplateAdmin } from 'components/templates';
import { Card, Input, OrangeButton } from 'components/atoms';
import { read, utils } from 'xlsx';
import { Loading } from 'components/molecules';
import { Table } from 'components/organisms';
import { baseUrl, headers } from 'api';
import { toast } from 'react-toastify';
import { cpfMask } from 'helpers';

import XLSIcon from 'assets/icons/xls_icon.svg';
import * as Style from './styles';

export const Importacao = () => {
  const [fileName, setFileName] = useState('enviar_arquivo.xlsx');
  const [rows, setRows] = useState();
  const [rowsFiltered, setRowsFiltered] = useState();
  const [rowsUploadedFiltered, setRowsUploadedFiltered] = useState();
  const [filterText, setFilterText] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [pageSelected, setPageSelected] = useState(1);
  const pageSize = useMemo(() => 20, []);
  const [isLoading, setIsLoading] = useState(false);

  const handlePageChange = (page) => {
    setPageSelected(page.selected + 1);
  };

  const handleUploadFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        setRows(json);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  function fillCpf(value) {
    const length = 11 - value.toString().length + 1;
    return Array(length).join('0') + value;
  }

  function formatDateToSend(value) {
    const timestamp = value - 1;
    const excelEpoch = new Date('1900-01-01'); // O Epoch do Excel é em 30/12/1899
    const date = new Date(excelEpoch.getTime() + timestamp * 24 * 60 * 60 * 1000);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function verifyMatricula(value) {
    if (value === '1 - Aprovados' || value === 'A' || value === 'Aprovado') {
      return 'Aprovado';
    }
    return 'Em Processo';
  }

  const handleSubmitData = () => {
    const data = rows.map((row) => {
      return {
        ano_referencia: row.ANOREFERENCIA,
        mes_referencia: row.MESREFERENCIA,
        descricao_curso: row.DESCRICAOCURSO,
        tipo_curso: row.TIPOCURSO,
        segmento_curso: row.SEGMENTOCURSO,
        nome: row.NOMEALUNO,
        cpf: fillCpf(row.CPFALUNO),
        data_nascimento: formatDateToSend(row.DATANASCALUNO),
        esp_matricula: verifyMatricula(row.Esp_Matricula),
        uf_sede: row.UFSEDE,
        dt_inicio_curso: formatDateToSend(row.DTINICIOCURSO),
        dt_termino_curso: formatDateToSend(row.DTTERMINOCURSO),
        carga_horaria: row.CARGAHORARIATOTAL,
        municipio: row.MUNICIPIO,
        base_dados: 'EAD',
      };
    });
    fetch(`${process.env.REACT_APP_API_URL}/importacao-aluno/`, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Dados cadastrados com sucesso!');
        } else if (response.status === 403) {
          toast.error(`Você não tem permissão para executar essa ação. (${response.status})`);
        } else {
          toast.error(`Ocorreu um erro ao cadastrar os dados. (${response.status})`);
        }
      })
      .then(() => {
        const params = new URLSearchParams({
          page_size: pageSize,
          page: pageSelected,
          search: filterText,
        });
        getData(`?${params}`);
        setRowsUploadedFiltered();
      });
  };

  const getData = useCallback(async (params) => {
    setIsLoading(true);
    const data = await fetch(`${process.env.REACT_APP_API_URL}/importacao-aluno/${params}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem('@senac:token'),
      },
    });
    const response = await data.json();

    if (response) {
      const newRows = response?.results?.map((row) => {
        return {
          nome: row.nome,
          cpf: cpfMask(row.cpf),
          'mês e ano': row.ano_referencia && row.mes_referencia ? `${row.mes_referencia}/${row.ano_referencia}` : '',
          curso: row.descricao_curso,
          'carga horária': row.carga_horaria,
          'data conclusão': row.dt_termino_curso,
          situação: verifyMatricula(row.esp_matricula),
        };
      });
      setPageCount(response.total_pages);
      setRowsFiltered(newRows);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (pageSize && pageSelected && !rowsUploadedFiltered) {
      const params = new URLSearchParams({
        page_size: pageSize,
        page: pageSelected,
        search: filterText,
      });
      const waitUser = setTimeout(() => {
        getData(`?${params}`);
      }, 1000);

      return () => clearTimeout(waitUser);
    }
  }, [pageSize, pageSelected, filterText]);

  useEffect(() => {
    if (rows?.length) {
      const newRows = rows?.flatMap((row) => {
        const nome_aluno = row.NOMEALUNO.toLowerCase();
        const cpf_aluno = row.CPFALUNO;
        if (nome_aluno.includes(filterText.toLowerCase()) || cpf_aluno.includes(filterText)) {
          return {
            nome: row.NOMEALUNO,
            cpf: fillCpf(row.CPFALUNO),
            'mês e ano': `${row.MESREFERENCIA}/${row.ANOREFERENCIA}`,
            curso: row.DESCRICAOCURSO,
            'carga horária': row.CARGAHORARIATOTAL,
            'data conclusão': formatDateToSend(row.DTTERMINOCURSO),
            situação: verifyMatricula(row.Esp_Matricula),
          };
        }
      });
      setRowsUploadedFiltered(newRows?.filter((row) => row !== undefined));
    }
  }, [filterText, rows]);

  return (
    <TemplateAdmin page="Importação">
      {isLoading && <Loading text="Carregando dados..." />}
      <div className="container">
        <Style.Cards>
          <Card blue>
            <label htmlFor="input_file">
              <img src={XLSIcon} width={28} height={28} alt="" />
              <p>Importar Arquivo XLS</p>
            </label>
            <input
              type="file"
              name="input_file"
              id="input_file"
              hidden
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={handleUploadFile}
            />
          </Card>

          <Input
            border="var(--orange-senac)"
            placeholder="Buscar por CPF ou Nome"
            onChange={(e) => setFilterText(e.target.value)}
            value={filterText}
          />
        </Style.Cards>

        <Style.SendFile onClick={handleSubmitData}>
          <OrangeButton>Enviar</OrangeButton>
          <p>{fileName}</p>
        </Style.SendFile>

        <Style.Table>
          {rowsUploadedFiltered ? (
            <Table
              columns={['NOME', 'CPF', 'MÊS E ANO', 'CURSO', 'CARGA HORÁRIA', 'DATA CONCLUSÃO', 'SITUAÇÃO']}
              rows={rowsUploadedFiltered}
            />
          ) : (
            <Table
              columns={['NOME', 'CPF', 'MÊS E ANO', 'CURSO', 'CARGA HORÁRIA', 'DATA CONCLUSÃO', 'SITUAÇÃO']}
              rows={rowsFiltered}
              pageCount={pageCount}
              handlePageChange={handlePageChange}
              pageSelected={pageSelected}
            />
          )}
        </Style.Table>
      </div>
    </TemplateAdmin>
  );
};
